import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import { Alert } from 'reactstrap';

const NotFound = () => {
  return (
    <Layout>
      <Helmet title={'Tom Beyer: Error 404'} />
      <h1 className="pageTitle">Error 404: Page not found</h1>
      <Alert color="danger">Page not found :-(</Alert>
    </Layout>
  );
};

export default NotFound;
